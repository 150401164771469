// @todo enable next eslint rules
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';

/**
 * @todo add prop types
 *
 * @todo remove non-standard attributes
 * Attribute `key` has no affect on div element
 *
 * @todo use `classNames` utility for style class names combinations
 * @see https://github.com/JedWatson/classnames#classnames
 *
 * @todo add attributes to interactive elements
 * @see https://www.w3.org/TR/wai-aria-practices-1.1/examples/tabs/tabs-1/tabs.html
 */
const PriceTableTabs = ({
  id,
  heading,
  isActive,
  onClickTab,
}) => (
  <div
    className={['PriceTableTabs__tab', id, isActive && 'PriceTableTabs__tab_active'].filter(Boolean).join(' ')}
    key={id}
    onClick={onClickTab}
  >
    {heading}
  </div>
);

export default PriceTableTabs;
