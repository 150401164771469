// @todo enable next eslint rules
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';


/**
 * @todo use React `Portal` utility component
 * @see https://reactjs.org/docs/portals.html
 *
 * @todo use `classNames` utility for style class names combinations
 * @see https://github.com/JedWatson/classnames#classnames
 *
 * @todo add attributes to interactive elements
 * @see https://www.w3.org/TR/wai-aria-practices/examples/dialog-modal/dialog.html
 */
const Popup = ({
  isOpen,
  heading,
  children,
  closePopup,
}) => (
  <div className={['Popup', isOpen && 'open'].filter(Boolean).join(' ')}>
    <span className="Popup__overlay" onClick={() => closePopup(false)} />
    <div className="Popup__container">
      <div className="Popup__close" onClick={() => closePopup(false)} />
      <div className="Popup__heading">
        {heading}
      </div>
      <div className="Popup__content">
        {children}
      </div>
    </div>
  </div>
);

/**
 * @todo add default props
 */
Popup.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node,
  closePopup: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default Popup;
