import React, { Component } from 'react';

function createVatData(WrappedComponent, options = {}) {
  const {
    fetcher = () => Promise.resolve(),
  } = options;
  const wrappedComponentName = WrappedComponent.name
    || WrappedComponent.displayName
    || 'Component';

  class VatData extends Component {
    constructor(props) {
      super(props);

      this.state = {
        error: undefined,
        data: undefined,
        isLoading: true,
      };
    }

    componentDidMount() {
      fetcher()
        .then((data) => {
          this.setState({
            data,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            error,
            isLoading: false,
          });
        });
    }

    render() {
      const {
        error,
        data,
        isLoading,
      } = this.state;

      return (
        <WrappedComponent
          error={error}
          data={data}
          isLoading={isLoading}
          {...this.props}
        />
      );
    }
  }

  VatData.displayName = `${wrappedComponentName}-with-VatData`;

  return VatData;
}

export default options => WrappedComponent => createVatData(WrappedComponent, options);
