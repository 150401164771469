import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import './VatList.scss';

const VatList = ({ data, error, isLoading }) => {
  const sortedVatList = () => data && data.vats.filter(item => item.tax > 0).sort((a, b) => {
    const countryA = a.country.toUpperCase();
    const countryB = b.country.toUpperCase();
    if (countryA < countryB) return -1;
    if (countryA > countryB) return 1;
    return 0;
  });

  const renderVatItem = (item, key) => (
    <li key={key}>
      {item.country}
      {' '}
      <strong>
        {item.tax}
      </strong>
    </li>
  );

  return (
    <ul className="prices-vat-rates">
      {isLoading && (<li><Spinner /></li>)}
      {error && (<li>Something went wrong</li>)}
      {data && sortedVatList().map((item, index) => renderVatItem(item, index))}
    </ul>
  );
};

VatList.propTypes = {
  data: PropTypes.shape({
    success: PropTypes.bool,
    vats: PropTypes.arrayOf(
      PropTypes.shape({
        country: PropTypes.string,
        tax: PropTypes.number,
      }),
    ),
  }),
  isLoading: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
};

VatList.defaultProps = {
  data: undefined,
  isLoading: false,
  error: undefined,
};

export default VatList;
