// @todo enable next eslint rules
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @todo add default props
 *
 * @todo use `classNames` utility for style class names combinations
 * @see https://github.com/JedWatson/classnames#classnames
 */
const PriceTableColumn = ({
  heading,
  children,
  className,
  mouseLeaveEvent,
  ...others
}) => (
  <div className={['PriceTable__col', className].filter(Boolean).join(' ')} {...others} onMouseLeave={mouseLeaveEvent}>
    <span className="PriceTable__col_heading">
      {heading}
    </span>
    {children}
  </div>
);

PriceTableColumn.propTypes = {
  heading: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  mouseLeaveEvent: PropTypes.func,
};

export default PriceTableColumn;
