import fetch from '../../utils/fetch';

import withPriceData from './withPriceData';
import PriceTable from './PriceTable';


const normalizeData = (json = {}) => {
  /**
   * @todo use correct es6 syntax
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment#Object_destructuring
   * @example
   * // Bad
   * const {
   *   currency: {},
   * } = json;
   *
   * // Good
   * const {
   *   currency = {},
   * } = json;
   */
  const {
    academicLevels: rawAcademicLevels = [],
    deadlines: rawDeadlines = {},
    // currency: {},
    currency: { defaultCurrency, currencyInfo: rawCurrencies = {} } = {},
    env = 'production',
  } = json;

  /**
   * @todo use immutable patters
   * @see https://hackernoon.com/functional-programming-paradigms-in-modern-javascript-immutability-4e9751ca005c
   * @example
   * const {
   *   currency: { defaultCurrency: defaultName, currencyInfo: rawCurrencies } = {}
   * } = json;
   * const currencies = {
   *   defaultName,
   * };
   *
   * return {
   *   currencies,
   * };
   *
   * @todo properly structure data for usage in components
   * @see https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
   */
  // let { currency } = json;
  // currency.currentCurrency = json.currency.defaultCurrency;
  const currencies = Object
    .keys(rawCurrencies)
    .reduce((result, name) => {
      const { byCode, allCodes } = result;

      byCode[name] = rawCurrencies[name];
      allCodes.push(name);

      return {
        byCode,
        allCodes,
      };
    }, {
      byCode: {},
      allCodes: [],
    });

  currencies.defaultCode = defaultCurrency;

  const deadlines = {
    byHours: {},
    allHours: [],
    defaultHours: null,
  };

  const academicLevels = rawAcademicLevels.reduce((result, academicLevel) => {
    const { byId, allIds } = result;
    const { id } = academicLevel;

    byId[id] = academicLevel;
    allIds.push(id);

    return {
      byId,
      allIds,
    };
  },
  {
    byId: {},
    allIds: [],
  });

  const tariffs = Object
    .keys(rawDeadlines)
    .reduce((acc, deadlineName) => Object
      .keys(rawDeadlines[deadlineName])
      .reduce((result, academicLevelId) => {
        const {
          byId,
          allIds,
          byDeadlineToAcademicLevel,
          byAcademicLevelToDeadline,
          // prices
        } = result;
        const {
          id,
          name,
          pricePerPage: pricePerItem,
          minPages: minItemCount,
          hrs: hours,
          hrsWriter: writerHours,
          isDefault,
          academicLevel,
        } = rawDeadlines[deadlineName][academicLevelId];

        if (!deadlines.byHours[hours]) {
          deadlines.byHours[hours] = {
            name,
            hours,
            writerHours,
          };
          deadlines.allHours.push(hours);
        }

        if (isDefault) {
          deadlines.defaultHours = hours;
        }

        byId[id] = {
          id,
          pricePerItem,
          minItemCount,
          deadline: hours,
          academicLevel,
        };
        allIds.push(id);
        /**
         * @todo remove redundant property prices
         * Price is already declared in tariff
         */
        // prices[id] = {pricePerItem};

        if (!byAcademicLevelToDeadline[academicLevel]) {
          byAcademicLevelToDeadline[academicLevel] = {};
        }

        byAcademicLevelToDeadline[academicLevel][hours] = id;

        if (!byDeadlineToAcademicLevel[hours]) {
          byDeadlineToAcademicLevel[hours] = {};
        }

        byDeadlineToAcademicLevel[hours][academicLevel] = id;

        return {
          byId,
          allIds,
          byAcademicLevelToDeadline,
          byDeadlineToAcademicLevel,
          // prices,
        };
      }, acc), {
      byId: {},
      allIds: [],
      byDeadlineToAcademicLevel: {},
      byAcademicLevelToDeadline: {},
      prices: {},
    });

  return {
    academicLevels,
    deadlines,
    tariffs,
    currencies,
    env,
  };
};

const fetcher = () => fetch('/assets/get_pricing_block/?siteId=169&format=json')
  .then(response => response.json())
  .then(normalizeData);

export default withPriceData({ fetcher })(PriceTable);
