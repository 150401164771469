// @todo enable next eslint rules
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';


/**
 * @todo add prop types
 *
 * @todo add default props
 *
 * @todo use `classNames` utility for style class names combinations
 * @see https://github.com/JedWatson/classnames#classnames
 */
const PriceTableCell = ({
  children,
  className,
  mouseEnterEvent,
  ishovered,
  index,
  ...others
}) => (
  <div
    className={['PriceTable__cell', className, ishovered && 'PriceTable__cell__hovered'].filter(Boolean).join(' ')}
    onMouseEnter={mouseEnterEvent}
    {...others}
  >
    {children}
  </div>
);

PriceTableCell.propTypes = {
  children: PropTypes.node,
  hoverEvent: PropTypes.func,
  isHovered: PropTypes.bool,
  className: PropTypes.string,
};

export default PriceTableCell;
