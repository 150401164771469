// @todo enable next eslint rules
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import Calculator from '../../components/Calculator';
import FaqSection from '../../components/FaqSection';
import { Accordion, AccordionItem } from '../../components/Accordion';
import VerticalTextBlock from '../../components/VerticalTextBlock';
import PriceTable from '../../components/PriceTable';
import Popup from '../../components/Popup';
import VatList from '../../components/VatList';
import CTA from '../../components/CTA';

import './index.scss';


/**
 * @todo rewrite as stateless function
 */
export default class Pricing extends React.Component {
  /**
   * @todo move state to component
   * gatsby pages should be stateless functions
   */
  state = {
    popup: false,
  };

  openPopup = (status) => {
    /**
     * @todo use destructuring assignment
     *
     * @todo rewrite handler usage of `setState`
     * @see https://reactjs.org/docs/react-component.html#setstate
     * @example
     * this.setState(
     *   ({ popup }) => ({
     *     popup: status !== undefined ? status : !popup,
     *   }),
     *   () => {
     *     const { popup } = this.state;
     *
     *     if (popup) {
     *       // ...
     *     } else {
     *       // ...
     *     }
     *   },
     * );
     */
    const popup = status !== undefined ? status : !this.state.popup;

    if (popup) {
      document.getElementsByTagName('html')[0].classList.add('overflow');
      document.getElementsByTagName('body')[0].classList.add('overflow');
    } else {
      document.getElementsByTagName('html')[0].classList.remove('overflow');
      document.getElementsByTagName('body')[0].classList.remove('overflow');
    }

    this.setState({
      popup,
    });
  };

  /**
   * @todo do not use array item index as Component key
   * @see https://reactjs.org/docs/lists-and-keys.html#keys
   *
   * @todo handle key events
   * Interactive elements should have focus management
   *
   * @todo add attributes to interactive static element
   * Interactive static elements should have role and description
   */
  render() {
    return (
      <Layout
        pageWrapperClassName="pricing-page"
        headerClassName="header_white-bg"
      >

        <Helmet
          title="Our Pricing Details | TakeAwayEssay.com"
          meta={[
            {
              name: 'description',
              content: 'Find out how much your essay will cost, what factors influence the final price, and how to get a lifetime discount as our returning customer. Place an order now and get a custom paper on practically any topic written according to your requirements.',
            },

            { property: 'og:title', content: 'Our Pricing Details | TakeAwayEssay.com' },
            {
              property: 'og:description',
              content: 'Find out how much your essay will cost, what factors influence the final price, and how to get a lifetime discount as our returning customer. Place an order now and get a custom paper on practically any topic written according to your requirements.',
            },
            { property: 'og:site_name', content: 'Takeawayessay.com' },
            { property: 'og:type', content: 'website' },
            { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
          ]}
          link={[
            { rel: 'canonical', href: 'https://takeawayessay.com/pricing.html' },
          ]}
        />

        <Popup
          isOpen={this.state.popup}
          heading="VAT for EU customers"
          closePopup={() => this.openPopup(false)}
        >
          <VatList />
        </Popup>

        <section className="pricetable-section">
          <div className="pricetable-section__container container">
            <h1 className="pricetable-section__heading">
              Pricing
            </h1>
          </div>
          <div className="pricetable-section__container container">
            <VerticalTextBlock
              sectionClassName="pricetable"
              dotsStyle="style-2"
              text="Price table"
            />
            <div className="pricetable-section__wrap">
              <div className="pricetable-section__pricetable">
                <PriceTable currencies={['GBP', 'USD']} />
              </div>
              <div className="pricetable-section__sidebar">
                <h3>
                  The final price for an essay depends on several factors:
                </h3>
                <ul className="dot-list">
                  <li>
                    Academic level
                  </li>
                  <li>
                    Number of pages
                  </li>
                  <li>
                    Urgency
                  </li>
                  <li>
                    Desired grade
                  </li>
                </ul>
                <p className="font-medium">
                  All prices are given in USD for 1 page.
                </p>
                <p>
                  These prices do not include the VAT (
                  <span className="vat-popup-link" onClick={() => this.openPopup()}>
                    value-added tax
                  </span>
                  ). Prices for EU customers are calculated according to the tariffs of the country
                  the customer is from.
                </p>
              </div>
            </div>
          </div>
        </section>

        <FaqSection>
          <Accordion>
            <AccordionItem title="Why is the price so high/low?">
              <p>
                We believe our prices are neither high, nor low. Our goal was to find a perfect
                balance between the
                ability to hire competent writers and pay them a decent salary and providing our
                services at a fair
                price for customers. In our humble opinion, we found such a balance.
              </p>
            </AccordionItem>

            <AccordionItem title="How is the price calculated?">
              <p>
                There are several factors that influence the final price for your order.
                First of all, it depends on your academic level: obviously,
                it takes more time and effort to write a master’s
                paper than an undergraduate one. So, the price depends
                on the number of pages you’ve ordered and the
                urgency.
              </p>
            </AccordionItem>

            <AccordionItem title="Can I get my money back?">
              <p>
                Yes, you can. Please visit our
                {' '}
                <Link to="/money-back-guarantee.html" rel="nofollow">
                  Money Back Guarantee page
                </Link>
                {' '}
                for more information.
              </p>
            </AccordionItem>

            <AccordionItem title="How can I pay for my paper? Is it safe?">
              <p>
                You can pay via your credit card. All payment methods are safe since we use
                industry-standard security measures to encrypt all our data. This means no one can
                access your bank account information when you use our website to make a payment.
              </p>
            </AccordionItem>

            <AccordionItem title="Can I pay for my paper in parts?">
              <p>
                If you’ve ordered a long paper like a thesis, you don’t have to wait until the last
                part of it is finished. Using our part-by-part payment feature,
                you can pay for every completed part of your order
                separately. This will cost you an additional 10%, but
                you get an opportunity to review every completed
                part of your paper and give suggestions to your writer.
              </p>
            </AccordionItem>

            <AccordionItem title="Can I see papers my writer has already written?">
              <p>
                Sure. For an additional payment you can see three pages, which are randomly chosen
                from papers completed by your writer. You can request a new writer up to 3 times.
              </p>
            </AccordionItem>

            <AccordionItem title="Why do papers in some disciplines cost more?">
              <p>
                There are disciplines that are considered more complicated since they require more
                profound research
                and skills:
              </p>
              <ul className="dot-list">
                <li>
                  Accounting
                </li>
                <li>
                  Architecture, building and planning
                </li>
                <li>
                  Chemistry
                </li>
                <li>
                  Computer science
                </li>
                <li>
                  Finance
                </li>
                <li>
                  Mathematics
                </li>
                <li>
                  Physics
                </li>
                <li>
                  Statistics
                </li>
                <li>
                  IT, web
                </li>
                <li>
                  Biology (and other life sciences)
                </li>
                <li>
                  Civil engineering
                </li>
                <li>
                  Engineering
                </li>
              </ul>
              <p>
                Papers in such disciplines will cost you 20% more. If you choose “Other” in the list
                of disciplines, our support team will review your order, and if
                it turns out the discipline is one of the mentioned
                above, they will send you a link to make an additional payment.
              </p>
            </AccordionItem>
          </Accordion>
        </FaqSection>

        <Calculator />

        <CTA isOnlyWaves />

      </Layout>
    );
  }
}
