import React from 'react';
import PropTypes from 'prop-types';


const FormattedPrice = ({
  value,
  format,
  currency,
  escape,
}) => {
  const { symbol, rate } = currency;
  const { fixed } = format;
  const price = (parseFloat(value) * rate).toFixed(fixed);
  const formattedPrice = `${symbol}${price}`;

  if (escape) {
    return (
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: formattedPrice }}
      />
    );
  }

  return formattedPrice;
};

FormattedPrice.propTypes = {
  value: PropTypes.string.isRequired,
  format: PropTypes.shape({
    fixed: PropTypes.number.isRequired,
  }),
  currency: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    rate: PropTypes.number.isRequired,
  }),
  escape: PropTypes.bool,
};

FormattedPrice.defaultProps = {
  format: {
    fixed: 2,
  },
  currency: {
    symbol: '$',
    rate: 1,
  },
  escape: false,
};

export default FormattedPrice;
