import React, { Component } from 'react';


function createPriceData(WrappedComponent, options = {}) {
  const {
    fetcher = () => Promise.resolve(),
  } = options;
  const wrappedComponentName = WrappedComponent.name
    || WrappedComponent.displayName
    || 'Component';

  class PriceData extends Component {
    constructor(props) {
      super(props);

      this.state = {
        error: undefined,
        data: undefined,
        // tariffs: undefined,
        isLoading: true,
      };
    }

    componentDidMount() {
      fetcher()
        .then((data) => {
          // const tariffs = Object.assign({}, data.tariffs.byId);
          // data.currency.currentCurrency = data.currency.defaultCurrency;

          this.setState({
            data,
            isLoading: false,
            // tariffs
          });
        })
        .catch((error) => {
          this.setState({
            error,
            isLoading: false,
          });
        });
    }

    /**
     * @todo use destructuring assignment
     *
     * @todo use native methods instead of for...in
     * @example
     * // Bad
     * for (let tariff in tariffs) {
     *   // ...
     * }
     *
     * // Good
     * Object.keys(tariffs).forEach((tariffId) => {
     *   // ...
     * });
     *
     * @todo use setState properly
     * @see https://reactjs.org/docs/state-and-lifecycle.html#using-state-correctly
     *
     * @todo useless state change
     * In that case you only need to change current currency code.
     * Recalculating prices in state is useless and very heavy.
     *
     * @todo move currency change handler closer to trigger component
     * `withPriceData` HOC should only control data fetching.
     * Do not mix ui state with server data.
     */
    // setCurrency (value) {
    //   let changedTariffs = this.state.data.tariffs.byId;
    //
    //   for (let tarif in changedTariffs) {
    //     changedTariffs[tarif].pricePerItem =
    //       parseFloat(
    //         this.state.data.tariffs.prices[tarif].pricePerItem
    //         * this.state.data.currency.currencyInfo[value].rate,
    //       ).toFixed(2);
    //   }
    //
    //   let data = {...this.state.data};
    //   data.tariffs.byId = changedTariffs;
    //   data.currency.currentCurrency = value;
    //   this.setState({data});
    // }

    render() {
      const {
        error,
        data,
        isLoading,
      } = this.state;

      return (
        <WrappedComponent
          error={error}
          data={data}
          // setCurrency={val => this.setCurrency(val)}
          isLoading={isLoading}
          {...this.props}
        />
      );
    }
  }

  PriceData.displayName = `${wrappedComponentName}-with-PriceData`;

  return PriceData;
}

export default options => WrappedComponent => createPriceData(WrappedComponent, options);
