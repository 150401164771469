import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Spinner from '../Spinner';
import FormattedPrice from '../FormattedPrice';

import PriceTableColumn from './PriceTableColumn';
import PriceTableCell from './PriceTableCell';
import PriceTableTabs from './PriceTableTabs';

import './PriceTable.scss';


class PriceTable extends Component {
  state = {
    defaultDynamicLevel: 1,
    currentTabId: 1,
    hoverCellIndex: null,
    currencyCode: undefined,
  };

  onClickTab = (id) => {
    this.setState({
      defaultDynamicLevel: id,
      currentTabId: id,
    });
  };

  handleCurrencyChange = (event) => {
    this.setState({ currencyCode: event.target.value });
  };

  hoverCell = (index) => {
    this.setState({
      hoverCellIndex: index,
    });
  };

  renderTariff(academicLevelId, deadlineHours, index) {
    const {
      data: {
        tariffs: { byId, byAcademicLevelToDeadline },
        currencies: { byCode, defaultCode },
      },
    } = this.props;
    const { currencyCode = defaultCode, hoverCellIndex } = this.state;
    const id = byAcademicLevelToDeadline[academicLevelId][deadlineHours];
    const { pricePerItem } = (byId[id] || {});
    const currency = byCode[currencyCode];

    return (
      <PriceTableCell
        key={`${academicLevelId}-${deadlineHours}`}
        ishovered={hoverCellIndex === index}
        mouseEnterEvent={() => this.hoverCell(index)}
      >
        {pricePerItem ? (
          <FormattedPrice value={pricePerItem} currency={currency} escape />
        ) : 'N/A' }
      </PriceTableCell>
    );
  }

  renderDeadline(hours, index) {
    const { data: { deadlines: { byHours } } } = this.props;
    const { hoverCellIndex } = this.state;
    const { name } = byHours[hours];

    return (
      <PriceTableCell
        key={hours}
        ishovered={hoverCellIndex === index}
        mouseEnterEvent={() => this.hoverCell(index)}
      >
        {name}
      </PriceTableCell>
    );
  }

  /**
   * @todo redundant render method
   */
  renderDynamicLevel(deadlineHours) {
    const { defaultDynamicLevel } = this.state;

    return this.renderAcademicLevel(defaultDynamicLevel, deadlineHours, 'PriceTable__dinamic');
  }

  renderTabs(id, deadlineHours) {
    const { data: { academicLevels: { byId } } } = this.props;
    const { currentTabId } = this.state;
    const { title } = byId[id];

    return (
      <PriceTableTabs
        key={id}
        heading={title}
        isActive={currentTabId === id}
        onClickTab={() => this.onClickTab(id, deadlineHours)}
      />
    );
  }

  renderAcademicLevel(id, deadlineHours, className) {
    const { data: { academicLevels: { byId } } } = this.props;
    const { title } = byId[id];

    return (
      <PriceTableColumn
        key={id}
        heading={title}
        className={className}
        mouseLeaveEvent={() => this.hoverCell(null)}
      >
        {deadlineHours.map((hours, index) => this.renderTariff(id, hours, index))}
      </PriceTableColumn>
    );
  }

  renderCurrencyMenu() {
    const {
      data: {
        currencies: {
          byCode,
          allCodes,
          defaultCode,
        },
      },
    } = this.props;

    if (allCodes.length < 2) return null;

    return (
      <div className="PriceTable__currancy">
        <select
          className="PriceTable__currancy__select"
          onChange={this.handleCurrencyChange}
          defaultValue={defaultCode}
        >
          {allCodes
            .map((code) => {
              const { symbol } = byCode[code];

              return (
                <option
                  key={code}
                  value={code}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: `${symbol} ${code}`,
                  }}
                />
              );
            })
          }
        </select>
      </div>
    );
  }

  render() {
    const { data, isLoading } = this.props;
    let academicLevelIds = [];
    let deadlineHours = [];

    if (data) {
      const { academicLevels, deadlines } = data;

      academicLevelIds = academicLevels.allIds.sort((a, b) => (a - b));
      deadlineHours = deadlines.allHours.sort((a, b) => (b - a));
    }

    return (
      <div className={['PriceTable', isLoading && 'loaded'].filter(Boolean).join(' ')}>
        {isLoading && <Spinner />}
        {data && (
          <Fragment>
            {this.renderCurrencyMenu()}
            <div className="PriceTableTabs">
              {academicLevelIds.map(id => this.renderTabs(id, deadlineHours))}
            </div>
            <PriceTableColumn heading="Deadline" className="PriceTable__deadlines">
              {deadlineHours.map((hours, index) => this.renderDeadline(hours, index))}
            </PriceTableColumn>
            {academicLevelIds.map(id => this.renderAcademicLevel(id, deadlineHours))}
            {this.renderDynamicLevel(deadlineHours)}
          </Fragment>
        )}
      </div>
    );
  }
}

PriceTable.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    academicLevels: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    deadlines: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    currencies: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    tariffs: PropTypes.object,
  }),
  isLoading: PropTypes.bool,
};

PriceTable.defaultProps = {
  data: null,
  isLoading: false,
};

export default PriceTable;
